import UIComponent from "../ui/component.js";

export class UploadProfitable extends UIComponent {

    init() {


    }

    update(data) {
        // console.log(data);
        this.title = data.title;
        this.cost = data.cost;
        this.old_cost = data.old_cost + '₽';
        this.discount = data.discount;
        this.payProfitableBtn.amount = data.amount;
    }


}
