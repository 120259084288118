import goals from "../utils/goals";

const USER_LIFETIME_UPDATE_INTERVAL = 1

export const initUserWebLifetime = () => {
    const userLifetime = localStorage.getItem('user-lifetime')
    const lifetimeGoalWasSend = localStorage.getItem('user-lifetime-goal-is-sent')

    if (!lifetimeGoalWasSend && userLifetime === '30') {
        localStorage.setItem('user-lifetime-goal-is-sent', true)
        goals.reach('time_30')
        return
    }

    if (!lifetimeGoalWasSend || !userLifetime || userLifetime < 30) {
        addUserLifetime(userLifetime)
    }
}

const addUserLifetime = (userLifetime) => {
    setTimeout(() => {
        localStorage.setItem('user-lifetime', Number(userLifetime) + USER_LIFETIME_UPDATE_INTERVAL)
        initUserWebLifetime()
    }, USER_LIFETIME_UPDATE_INTERVAL * 1000)
}
