import UIComponent from "../ui/component";

export class Auth extends UIComponent {

    init() {
        this.includeComponents({
            'authBtn': '.js-telegram-auth-btn',
            'widget': 'document@.js-telegram-widget',
        });
        // console.log(this);
        
        this.authBtn.on('click', evt => {
            evt.preventDefault();
            this.startTelegramAuth();
        });
    }

    async startTelegramAuth() {
        await Telegram.Login.auth(
            {
                bot_id: this.widget.attr('data-telegram-bot-id'),
                request_access: true,
            },
            async (data) => {
                window.location.href = '/api/telegram/callback?' + this.serialize(data);
            }
        )
    }

    serialize(obj) {
        const str = [];

        for (const p in obj) {
            if (obj.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        }

        return str.join("&");
    }
}
