import UIComponent from '../ui/component';
import api from '../api';
export class MyFiles extends UIComponent {
    init() {
        this.updateFiles();

        this.search.onChange = this.list.filterItems.bind(this.list);

        setInterval(() => {
            this.updateFiles();
        }, 5000);
    }


    updateFiles() {
        let filesIds = this.list.getFilesIdsNeedsRefresh();
        if (filesIds.length == 0) {
            return;
        }
        if (this.updating) {
            return;
        }
        
        // Обновление списка файлов
        this.updating = true;
        api
            .post(this.attr('data-status-url'), {
                id: filesIds
            })
            .then((response) => {
                this.updating = false;
                response.map(file => { 
                    this.list.updateItemById(file.id, file); 
                    this.content?.updateIfId(file.id, file);
                });
                
            }).catch(() => {});
    }
}
