import { Modal as BsModal } from 'bootstrap';
import { Modal } from 'tbxjs/components/Modal';

export class AddBlockModal extends Modal {
    init() {
        this.bsModal = new BsModal(this.element);
        console.log(this.bsModal);
        this.find('.btn-close').addEventListener('click', () => { this.onCloseBtnClick() });
    }

    show(block) {
        this.selectedBlock = block;
        super.show();
    }
}