import { AbstractApi } from ".";

const axios = window.axios;

class TechSupportApi extends AbstractApi {
    submitUrl = 'support';

    async submit(data) {
        let fd = new FormData();
        fd.append('address', data.address)
        fd.append('bio', data.bio)
        fd.append('type', data.type)
        fd.append('invoice', data.invoice)

        if (data.attachment) {
            fd.append('attachment', data.attachment)
        }

        return new Promise((resolve, reject) => {
            axios.post(this.submitUrl, fd, {headers: {'Content-Type': 'multipart/form-data'}}).then((response) => {
                this.processResponse(response, resolve, reject)
            }).catch((error) => {
                this.processResponse(error, resolve, reject)
            });
        });
    }
}

export default new TechSupportApi;
