import goals from "../utils/goals";
import Api from "../api";
import { Button } from "../ui/components/Button";

export class SelectTariff extends Button {

    
    init() {
        super.init();

        this.includeComponents({
            'paymentModal': 'document@#paymentModal',
        });

        this.on('click', () => {
            this.paymentModal.show(this.minutes);
        });

    }

    
}
