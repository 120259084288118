import { MIN_AVILABLE_MINUTES, MAX_AVILABLE_MINUTES, RANGE_MIN_PRICE, RANGE_MAX_PRICE, RANGE_MIN_VALUE } from './constants'
import { addAttributesToEl, removeAttributesFromEl } from '../../utils/attributes'
import { getCorrectWord, pluralizeWord } from '../../utils/prulization'
import goals from '../../utils/goals'
import { Modal } from '../../ui/components/Modal'
export class PaymentForm extends Modal {
    init() {
        super.init();

        this.includeComponents({
            // 'errorBag': 'document@.js-upload-form-error',
            'paymentSelectModal': 'document@#paymentSelectModal',
        });

        this.currentRangePrice = null
        this.currentDiscountInPercent = null

        this.paymentData = {
            minutes: 60,
            amount: 0,
            amountWithDiscount: 0,
            appliedsCoupon: [],
        }

        this.priceRanges = window.app.prices;
        // this.initSpecialPrices()

        let inputElement = this.input.find('input');
        this.inputElement = inputElement;

        this.input.element.addEventListener('change', this.onInputChange.bind(this));

        this.range.onChange = (minutes) => {
            inputElement.value = minutes;
            this.calculatePrice(inputElement.value);
        }

        inputElement.value = 60;
        this.calculatePrice(inputElement.value);

        this.payBtn.on('click', () => {
            this.payBtn.disable();
            this.paymentSelectModal.show(this, this.attr('url'), this.paymentData);
        });
    }

    onInputChange() {

        if (this.inputElement.value < RANGE_MIN_VALUE) {
            this.inputElement.value = RANGE_MIN_VALUE;
        }
        if (this.inputElement.value > MAX_AVILABLE_MINUTES) {
            this.inputElement.value = MAX_AVILABLE_MINUTES;
        }
        this.inputElement.value = parseInt(this.inputElement.value);

        this.onMinutesChange(this.inputElement.value)
        this.calculatePrice(this.inputElement.value);
    }

    show(amount = null) {
        super.show();
        if (amount) {
            this.inputElement.value = amount;
            this.onInputChange();
            // this.onMinutesChange(amount);
        }
    }

    onPaymentSuccess(response, paymentData) {
        if (paymentData.links.account != null) {
            setInterval(() => {
                window.location.href = paymentData.links.account;
            }, 1000)
        } else {
            setInterval(() => {
                window.location.reload();
            }, 5000)
        }

        this.payBtn.enable();
    }

    onPaymentError(errors) {
        this.paymentSelectModal.hide();
        this.show();
        this.errorBag.show(errors);
        this.payBtn.enable();
    }

    onPaymentCancel(response) {
        if (response.links.account != null) {
            window.location.href = response.links.account;
        }
        this.payBtn.enable();
        this.show();
    }

    onMinutesChange(value) {
        if (this.paymentData.minutes == value) {
            return;
        }
        this.paymentData.minutes = value;
        this.range.setValue(value);

    }

    initOnContinuePayment() {
        this.paymentContinuePaymentBtn.on('click', async () => {
            try {
                goals.reach('refill')

                addAttributesToEl(this.paymentContinuePaymentBtn, 'disabled', true);
                this.paymentContinuePaymentBtn.find('.spinner-border').removeClass('d-none');

                await this.showPaymentWidget()
            } catch (err) {
                console.error(err);
            } finally {
                removeAttributesFromEl(this.paymentContinuePaymentBtn, 'disabled');
                this.paymentContinuePaymentBtn.find('.spinner-border').addClass('d-none');
            }
        })
    }

    initOnCloseNotification() {
        this.paymentFormCloseNotification.click(() => {
            this.paymentFormContianer.find('.js-payment-form-error').addClass('d-none')
        })
    }

    calculatePrice(value) {
        let { rangeDelta, currentRange } = this.range.getRangeParamsByMinutes(value);
        this.paymentData.minutes = parseInt(value);

        const rangePrice = currentRange.price * parseInt(value);

        this.paymentData.amount = Math.round(rangePrice*100)/100;

        this.updatePriceContent(this.paymentData.amount)
    }

    updatePriceContent(price) {

        this.minutes = pluralizeWord(
            this.paymentData.minutes,
            { en: 'minute', ru: ['минут', 'минута', 'минуты', 'минут'], fr: 'minute', es: 'minuto' }
        );

        /** временный костыль для казахского языка */
        if (window.app.currentLocale === 'kz') {
            this.minutes = this.paymentData.minutes + ' минут'
        }

        this.price = price + getCorrectWord({ ru: '₽', en: '$', fr: '$', es: '$', kz: '$' });

    }

}
