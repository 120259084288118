import FilesApi from '../api/files';
import goals from '../utils/goals';
import UIComponent from '../ui/component';
import { isAnyModalOpen } from '../ui/components/Modal';

export class FileContent extends UIComponent {
    init() {
        // this.axios = window.axios
        this.includeComponents({
            'paymentSelectModal': 'document@#paymentSelectModal',
            // 'errorBag': 'document@.js-upload-form-error',
            'selectLang': '.selectLang',
        });

        if (this.preloader) {
            setInterval(() => {
                this.updatePreloaderText();
            }, 8000);
            this.updatePreloaderText();
        }

        this.initTranscribeEvent();
    }

    updatePreloaderText() {
        this.preloader.text = window.app.preloader[Math.floor(Math.random() * window.app.preloader.length)];
    }
    updateIfId(id, file) {
        if (id != this.fileid) {
            return;
        }

        if (file.status && this.status != file.status) {
            if (!isAnyModalOpen()) {
                window.location.reload();
            }
        }

        if (!this.preloader) {
            return;
        }

        this.preloader.progress.css("width", file.progress + '%');

    }

    onCompletedNotificationStatus() {
        if (!this.jsHeader.find('.--positive-notification')) {
            return
        }

        const isNotFirstFileView = localStorage.getItem('is-not-first-file-view')

        if (!isNotFirstFileView) {
            goals.reach('showedPromoPay')
            // localStorage.setItem('is-not-first-file-view', true)
        }

        this.initTranscribeEvent()
    }

    onPaymentSuccess(response, paymentData) {
        setTimeout(() => {
            window.location.reload();
        }, 5000);
    }

    onPaymentCancel() {
        this.errorBag.show('payment.cancelled');
    }

    onPaymentError(error) {
        this.errorBag.show(error); // payment-error
    }

    initTranscribeEvent() {
        this.extraPayment?.on('click', async () => {
            const isNotFirstClick = localStorage.getItem('is-not-first-surcharge-click')

            if (!isNotFirstClick) {
                localStorage.setItem('is-not-first-surcharge-click', true)
                goals.reach('promoPay')
            }

            // const filePath = this.transcriptionFilePath.attr('value');
            // const fileData = await FilesApi.с(this.filepath)
            this.paymentSelectModal.show(this, this.extraPayment.attr('data-url'), { files: [this.filepath] });

            // if (fileData?.needPay) {
            //     this.payment.getPaymentWidget(fileData?.paymentData)
            // }
        })

        this.transcriptionTo?.on('click', async () => {
            // console.log(this);
            // const filePath = this.transcriptionFilePath.attr('value');
            const language = this.selectLang.element.value;

            try {
                const fileData = await FilesApi.transcribeWithLanguage(this.filepath, language, 'toLanguage')
            } catch (e) {
                this.errorBag.show(e);
            }

        })
    }
}
