import { debounce } from "../../utils/debounce"
import UIComponent from "../../ui/component"

export class FilesListHeaderSearch extends UIComponent {
    init() {
        
        this.on('keyup', () => {
            debounce(500, () => { this.onChange(this.element.value) }, 500)
        });

    }

    onChange () {
    }
}
