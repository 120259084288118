/**
 * 
 * @param {(Object|Object[])} $el elements to who we want add a attribute
 * @param {string} attribute added attribute name
 * @param {string} value value of added attribute
 * @return {void}
 */
export const addAttributesToEl = ($el = [], attribute = '', value = '') => {
    if (!$el || !attribute || !value || Array.isArray($el) && !$el.length) {
        return
    }

    if (!Array.isArray($el)) {
        $el.attr(attribute, value)
        return
    }

    if ($el.each) {
        $el.each((_, $item) => {
            $item.setAttribute(attribute, value)
        })
        return
    }

    $el.forEach(($elData) => {
        $elData.attr(attribute, value)
    })
}

/**
 * 
 * @param {(Object|Object[])} $el elements to who we want remove a attribute
 * @param {string} attribute removed attribute name
 * @return {void}
 */
export const removeAttributesFromEl = ($el = [], attribute = '') => {
    if (!$el || !attribute || Array.isArray($el) && !$el.length) {
        return
    }

    if (!Array.isArray($el)) {
        $el.removeAttr(attribute)
    }
    
    if ($el.each) {
        $el.each((_, $item) => {
            $item.removeAttribute(attribute)
        })
        return
    }

    $el.forEach(($elData) => {
        $elData.removeAttr(attribute)
    })
}
