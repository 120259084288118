import Dropzone from "dropzone";
import goals from '../utils/goals';
import UIComponent from '../ui/component';
// import { RecordForm } from './record';

export class UploadForm extends UIComponent {
    maxFiles = 100;

    init() {
        this.isPromoForm = false;
        this.files = [];

        this.initDropzone();

        if (this.uploadByLink) {
            this.uploadByLink.onSuccess = (file) => { this.injectFile(file) };
            this.uploadByLink.onError = (errors) => { this.errorBag.show(errors) };
        }

        // this.restoreFiles();
        // console.log(this);
    }

    // Инициализация компоненты для загрузки файлов
    initDropzone() {
        let previewTemplate = this.previewTemplateContainer.removeClass('d-none').html();
        this.previewTemplateContainer.remove();

        // this.maxFiles = this.isPromoForm ? 1 : 100;

        this.dropzone = new Dropzone(document.body, {
            url: this.dropzoneForm.attr('action'),
            createImageThumbnails: false,
            maxFiles: this.maxFiles,
            maxFilesize: 8192,
            previewTemplate: previewTemplate,
            chunking: true,
            forceChunking: true,
            chunkSize: 50000000,
            // parallelUploads: 1,
            autoQueue: true, // Make sure the files aren't queued until manually added
            previewsContainer: this.previewContainer.el(), // Define the container to display the previews
            clickable: ['.fileinput-button', '.js-upload-icon']
        });

        this.dropzone.on('addedfile', this.onAddFile.bind(this));
        this.dropzone.on('error', this.onErrorFile.bind(this));
        this.dropzone.on('complete', this.onSendFile.bind(this));
        this.dropzone.on('removedfile', this.onRemoveFile.bind(this));
    }

    onAddFile (response) {
        goals.reach('startFileUpload');
    }

    onErrorFile (file, error) {
        // console.log(this, error);
        this.dropzone.removeFile(file);

        if (['Upload canceled.', 'You can not upload any more files.'].includes(error)) {
            return
        }

        if (typeof error === 'string' && error.indexOf('Max filesize')) {
            this.errorBag.show('too-big-file', true)
            return
        }

        if (error.data) {
            this.errorBag.show(error.data.errors);
        }
    }

    onSendFile = (file) => {
        if (!file || file.status === 'error' || !this.dropzone.files.length && !file) {
            return
        }

        if (this.isPromoForm) {
            goals.reach('fileUpload'); // Серега специально попросил отправлять это событие ТОЛЬКО для промо формы
        }

        let path = file.path;
        let duration = file.duration;
        let row = file.previewElement;
        let icon = file.icon

        if (file.xhr) {
            // Специфический ответ dropzone
            let data = JSON.parse(file.xhr.response).data;
            if (data.duration) {
                duration = data.duration;
            }
            if (data.icon) {
                icon = data.icon
            }
            path = data.path;
        }

        if (duration) {
            row.querySelector('.js-formupload-duration').innerHTML = duration;
        } else {
            row.querySelector('.js-formupload-duration').parent().remove();
        }
        if (icon) {
            row.querySelector('.js-preview-icon').setAttribute('data', icon)
        } else {
            row.querySelector('.js-preview-icon').setAttribute('data', '/img/file-previews/videos.svg')
        }

        row.querySelector('.js-preview-field').setAttribute('value', path);

        row.querySelector('.progress').remove();

        this.onFilesChanged();

        this.dropzoneUpload.enable();
    }

    onRemoveFile = () => {
        goals.reach('fileRemoved');
        this.onFilesChanged();
    }

    /**
     * Событие которое вызывается когда список загруженных файлов изменился
     * Нужно принудительно его вызывать в других событиях
     */
    onFilesChanged() {
        if (this.dropzone.files.length >= 1) {
            this.onFilesExists(this.dropzone.files.length);
        } else {
            this.onFilesEmpty();
        }

        // this.saveFiles();
    }
    /**
     * Событие когда список файлов обновился и он пуст
     */
    onFilesEmpty() {
        this.checkout.hide();

        this.dropzoneForm.show();
        this.uploadByLink?.show()
        this.dropzoneUpload.enable();
    }

    /**
     * Событие когда список файлов обновился и содержит хотя бы 1 элемент
     */
    onFilesExists(countFiles) {
        this.checkout.show();
        this.checkout.update(this.getFiles());

        if (countFiles >= this.maxFiles) {
            this.dropzoneForm.hide();
            this.uploadByLink?.hide();
        } else {
            this.dropzoneForm.show();
            this.uploadByLink?.show();
            this.dropzoneUpload.enable();
        }

    }

    getFiles() {
        var object = {};
        let form = this.previewContainer.element.closest('form');
        // new FormData(form).forEach((value, key) => object[key] = value);
        // delete object['files[]'];

        object['files'] = [];
        form.querySelectorAll('input[name]')?.forEach((element) => {
            if (element.getAttribute('name') == 'files[]') {
                object['files'].push(element.value);
            }
        });

        object = {...object, ...this.checkout.s_language?.getValue(), ...this.checkout.s_speakers?.getValue(), ...this.checkout.s_subtitles?.getValue()};
        return object;
    }

    uploadAudioFile(blob) {
        const formData = new FormData();
        const imageFile = new File([blob], `file.mp3`, {
            type: blob.type,
        });
        formData.append('file', imageFile);
        window.axios.post('api/files/upload', formData)
            .then(response => {
                response.data.data.name = 'Audio ' + (new Date()).getFullYear() + '-' + (new Date()).getMonth() + '-' + (new Date()).getDay() + '-' + (new Date()).getHours() + '-' + (new Date()).getMinutes();
                this.addFile(response.data.data);
            })
            .catch(err => {
                console.log(err);
            });
    }

    /**
     * Сохраняет файлы в LocalStorage
     */
    // saveFiles() {
    //     console.log(this.dropzone.files);
    //     localStorage.setItem("draftFiles", JSON.stringify(this.dropzone.files));
    // }

    // restoreFiles() {

    //     let files = JSON.parse(localStorage.getItem("draftFiles"));
    //     if (files && files.length) {
    //         for (let i in files) {
    //             // this.injectFile(files[i]);
    //         }
    //     }

    // }

    /*
    * Добавляет загруженные файлы в форму получая на вход ответ от api
    * Для случаев когда файлы загружаются не через dropzone
    */
    injectFile(mockFile) {
        if (this.isPromoForm) {
            this.uploadByLink?.hide();
        }

        const filePreview = mockFile.icon ? mockFile.icon : 'img/file-previews/videos.svg'

        this.dropzone.files.push(mockFile)
        this.dropzone.displayExistingFile(mockFile, filePreview)
    }
}
