import { Modal } from "../ui/components/Modal";
import api from "../api";

export class PaymentRobokassa extends Modal {
    initialized = false;
    type = 'robokassa';
    link = null;

    init() {
        super.init();

        if (this.initialized) {
            return;
        }

        this.link = 'https://auth.robokassa.kz/Merchant/Index.aspx';

        this.initialized = true;
    }

    show(paymentData) {
        if (!paymentData) {
            return
        }
        this.paymentData = paymentData;

        this.link += '?MerchantLogin=' +  this.paymentData.login
            + '&OutSum=' + this.paymentData.amount
            + '&OutSumCurrency=' + this.paymentData.currency
            + '&EncodingValue=' + this.paymentData.encoding
            + '&IsTest=' + this.paymentData.isTest
            + '&Description=' + this.paymentData.description
            + '&Culture=' + this.paymentData.culture
            + '&Shp_invoice=' + this.paymentData.invoice
            + '&SignatureValue=' + this.paymentData.sig;

        document.location.href = this.link;
    }

    /** Общие события виджета */
    onSuccess(response, paymentData) {

    }

    onCancel() {

    }

    onError(errors) {
    }

}
