import UIComponent from "../component";
import Cleave from 'cleave.js';

export class MaskField extends UIComponent {
    init() {


        let options = {
            numericOnly: true,
            delimiters: ["+7 ", " ", " ", " "],
            blocks: [0, 3, 3, 4]
        };
        new Cleave(this.element, options);

    }


}

