import goals from "../utils/goals";
import { Modal } from "../ui/components/Modal";
import Api from "../api";

export class PaymentSelect extends Modal {

    closeErrorAfterTimer = 5000;

    init() {
        super.init();

        this.availableWidgets = [];

        if (this.payment_paypal) {
            this.requireComponents({
                'widget_paypal': 'document@#paypalPaymentModal',
            });
            this.payment_paypal.on('click', () => { this.onPaymentTypeSelected(this['widget_paypal']) });
            this.availableWidgets.push('paypal');
        }
        if (this.payment_cloudpayments) {
            this.requireComponents({
                'widget_cloudpayments': 'document@#cloudpaymentsPaymentModal',
            });
            this.payment_cloudpayments.on('click', () => { this.onPaymentTypeSelected(this['widget_cloudpayments']) });
            this.availableWidgets.push('cloudpayments');
        }
        if (this.payment_robokassa) {
            this.requireComponents({
                'widget_robokassa': 'document@#robokassaPaymentModal',
            });
            this.payment_robokassa.on('click', () => { this.onPaymentTypeSelected(this['widget_robokassa']) });
            this.availableWidgets.push('robokassa');
        }
        if (this.payment_stripe) {
            this.requireComponents({
                'widget_stripe': 'document@#stripePaymentModal',
            });
            this.payment_stripe.on('click', () => { this.onPaymentTypeSelected(this['widget_stripe']) });
            this.availableWidgets.push('stripe');
        }
        if (this.payment_tinkoff) {
            this.requireComponents({
                'widget_tinkoff': 'document@#tinkoffPaymentModal',
            });
            this.payment_tinkoff.on('click', () => { this.onPaymentTypeSelected(this['widget_tinkoff']) });
            this.availableWidgets.push('tinkoff');
        }

    }

    onPaymentTypeSelected(widget) {
        widget.onSuccess = this.onPaymentSuccess.bind(this);
        widget.onCancel = this.onPaymentCancel.bind(this);
        widget.onError = this.onPaymentError.bind(this);
        widget.onCloseBtnClick = this.onPaymentCancel.bind(this);

        Api.post(
            this.invoiceRequestUrl,
            {
                payment_type: widget.type,
                ...this.invoiceRequestPayload
            },
        ).then((response) => {
            console.log(response);
            if (response.payment) {
                response = response.payment;
            }
            this.onInvoiceReceived(response);
            this.hide();
            widget.show(response);
        }).catch(this.onPaymentError.bind(this));


    }

    onInvoiceReceived(response) {
        console.log("onInvoiceReceived", response);
        this.invoice = response;
        if (!(this.caller && this.caller.onInvoiceReceived && this.caller.onInvoiceReceived())) {
            return;
        }
    }

    onCloseBtnClick() {
        this.onPaymentCancel();
    }

    onPaymentSuccess(response, paymentData) {
        console.log('PaymentSelect onPaymentSuccess', response, paymentData);
        goals.reach('paymentSuccess');
        if (!(this.caller && this.caller.onPaymentSuccess && this.caller.onPaymentSuccess(response, paymentData))) {
            return;
        }
    }

    onPaymentError(errors) {
        console.log('PaymentSelect onPaymentError', errors);
        if (!(this.caller && this.caller.onPaymentError && this.caller.onPaymentError(errors))) {
            return;
        }
    }

    onPaymentCancel(message) {
        console.log('PaymentSelect onPaymentCancel');
        if (!(this.caller && this.caller.onPaymentCancel && this.caller.onPaymentCancel(this.invoice))) {
            return;
        }
        goals.reach('paymentCancelled')
    }

    /**
     * Показать окно платежа
     */
    show(caller, invoiceRequestUrl, invoiceRequestPayload) {
        this.caller = caller;
        this.invoiceRequestUrl = invoiceRequestUrl;
        this.invoiceRequestPayload = invoiceRequestPayload;

        if (this.availableWidgets.length == 1) {
            // Если всего 1 виджет, то сразу тыкаем в него
            this.onPaymentTypeSelected(this['widget_' + this.availableWidgets[0]]);
            return;
        }

        super.show();
    }


    getCurrentErrorContainer = () => {
        if (this.paymentModalContainer && this.paymentModalContainer.hasClass('show')) {
            return 'payment-add-minutes'
        }

        if (this.contentHeader.find('.--positive-notification').length) {
            return 'payment-transcribe-full-video'
        }

        if (this.paymentUploadModalContainer.hasClass('show')) {
            return 'payment-modal-add-files'
        }

        return 'default'
    }
}
