import UIComponent from "../ui/component.js";

export class SettingsItem extends UIComponent {

    init() {

    }

    getValue() {
        if (!this.body.hasClass('show')) {
            return null;
        }
        let result = {};
        this.findAll('select').forEach(element => {
            result[element.getAttribute('name')] = element.value;
        });
        return result;
    }
}
