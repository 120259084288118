import UIComponent from "../ui/component.js";

export class TechSupport extends UIComponent {

    init() {
        this.includeComponents({
            'sendTechSupportMail': 'document@#sendTechSupportMail',
        });

        this.sendTechSupportMail.enable();
        this.sendTechSupportMail.on('click', this.handleSubmit.bind(this));
    }

    async handleSubmit(e) {

    }
}
