import UIComponent from "../component";
export class PasswordField extends UIComponent {
    init() {
        this.input = this.find('input[type="password"]');
        this.checkbox = this.find('input[type="checkbox"]');
        this.checkbox?.addEventListener('change', this.toggle);
        this.on('click', this.toggle.bind(this));
    }

    toggle() {
        if (!this.checkbox) {
            return;
        }
        if (this.checkbox.checked) {
            this.input.type = 'text';
        } else {
            this.input.type = 'password';
        }
    }
}

