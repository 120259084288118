/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

import axios from 'axios';
import * as Sentry from "@sentry/browser";
// import jQuery from 'jquery';

// window.$ = jQuery;
window.axios = axios;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

Sentry.init({
   dsn: window.SENTRY_DSN,

   // Set tracesSampleRate to 1.0 to capture 100%
   // of transactions for performance monitoring.
   // We recommend adjusting this value in production
   tracesSampleRate: 1.0,
});

if (window.user_id) {
   Sentry.setUser({ user_id: window.user_id });
}


