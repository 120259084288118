import './bootstrap';
import { Tooltip } from 'bootstrap';
import 'boxicons'
import { initUserWebLifetime } from './plugin/user-lifetime'
// import { jarallax } from "jarallax";
// import 'jarallax/dist/jarallax.min.css';
import 'smooth-scroll';
import Swiper from 'swiper';

import Tbx from "tbxjs"
import * as components from './ui/components'

initUserWebLifetime()

Tbx.registerComponents(components);
Tbx.init();

document.querySelectorAll('[ui-modal-target]').forEach(element => {
    let modalId = element.getAttribute('ui-modal-target');
    let target = document.querySelector(modalId);
    if (target && (target = Tbx.getComponentByElement(target))) {
        element.addEventListener('click', (e) => {
            // console.log(target);
            e.preventDefault();
            target.show();
        })

    }
});


document.querySelectorAll('.swiper').forEach(element => {
    // console.log(JSON.parse(element.getAttribute('data-swiper-options')));
    new Swiper(element, JSON.parse(element.getAttribute('data-swiper-options')));
});

// jarallax(document.querySelectorAll('.jarallax'), {
//     speed: 0.2,
// });

const tooltip = (() => {

    let tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));

    let tooltipList = tooltipTriggerList.map((tooltipTriggerEl) => new Tooltip(tooltipTriggerEl, { trigger: 'hover' }));

})();

