export const RANGE_MIN_VALUE = 60;

export const RANGE_MAX_VALUE = 2000;

/**
 * Это мог бы быть один набор констант для ползунка с минимальным и максимальным значением,
 * но и за косяка в вычислениях приходиться использовать RANGE_MIN_VALUE для установки минимального значения для ползунка.
 */
export const RANGE_MIN_PRICE = 60;

export const RANGE_MAX_PRICE = RANGE_MAX_VALUE;


export const TOTAL_RANGES = 3;

export const THIRD_PART_OF_RANGE_MAX_VALUE = RANGE_MAX_VALUE / TOTAL_RANGES


export const MIN_AVILABLE_MINUTES = 0;

export const MAX_AVILABLE_MINUTES = 50000;
