import { loadScript } from "@paypal/paypal-js";
import { Modal } from "../ui/components/Modal";
import api from "../api";

export class PaymentPaypal extends Modal {
    initialized = false;
    type = 'paypal';

    init() {
        super.init();

        if (this.initialized) {
            return;
        }

        loadScript({ "client-id": window.app.payments.paypal.client_id, 'components': "buttons,card-fields", 'locale': 'en_US' })

        this.initialized = true;
    }

    show(paymentData) {
        if (!paymentData) {
            return
        }

        document.querySelector('#paypalPaymentModalContent').innerHTML = '';
        window.paypal
            .Buttons({
                style: {
                    shape: "pill",
                    layout: "vertical",
                },
                createOrder: async () => { return await this.createOrderCallback(paymentData) },
                onApprove: async (data) => { return await this.onApproveCallback(paymentData, data) },
                onCancel: this.onCancel.bind(this),
                onError: this.onError.bind(this),
            })
            .render("#paypalPaymentModalContent");

        // new bootstrap.Modal('#paymentModal').hide();
        // bootstrap.Modal.getInstance(document.getElementById('paymentModal')).hide()
        // new bootstrap.Modal('#paypalPaymentModal').show();
        super.show();

    }

    // hide() {
    //     new bootstrap.Modal('#paypalPaymentModal').hide();
    // }

    async createOrderCallback (paymentData) {
        const response = await api.post(
            '/payment/paypal/create/' + paymentData.invoice,
        );
        return response.order_id;
    }

    async onApproveCallback (paymentData, data) {
        console.log('onApproveCallback', data);

        const response = await api.post(
            '/payment/paypal/capture/' + paymentData.invoice,
        );
        // console.log(response);
        this.hide();
        this.onSuccess(paymentData.invoice);
        // return response.data.data.order_id;
    }

    onSuccess(invoice) {

    }

    onCancel() {

    }

    onError(error) {

    }


    async handleSubmit(e) {
        e.preventDefault();
        this.setLoading(true);

        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                // Make sure to change this to your payment completion page
                return_url: confirmCallback,
                // receipt_email: emailAddress,
            },
        });

        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        if (error.type === "card_error" || error.type === "validation_error") {
            this.showMessage(error.message);
        } else {
            this.showMessage("An unexpected error occurred.");
        }

        this.setLoading(false);
    }

    showMessage(messageText) {
        const messageContainer = document.querySelector("#payment-message");

        messageContainer.classList.remove("hidden");
        messageContainer.textContent = messageText;

        setTimeout(function () {
            messageContainer.classList.add("hidden");
            messageContainer.textContent = "";
        }, 4000);
    }

    // setLoading(isLoading) {
    //     if (isLoading) {
    //         // Disable the button and show a spinner
    //         document.querySelector("#stripeSubmit").disabled = true;
    //         document.querySelector("#spinner").classList.remove("hidden");
    //         document.querySelector("#button-text").classList.add("hidden");
    //     } else {
    //         document.querySelector("#stripeSubmit").disabled = false;
    //         document.querySelector("#spinner").classList.add("hidden");
    //         document.querySelector("#button-text").classList.remove("hidden");
    //     }
    // }
}
