import UIComponent from '../ui/component';

export class FilesListItem extends UIComponent {
    init() {
        this.axios = window.axios;
        this.deleteBtn.on('click', this.deleteFile.bind(this));

        this.updateStatus(this.status)
    }

    deleteFile(event) {

        event.preventDefault()

        this.deleteBtn.disable();
        let itemId = this.itemid;

        this.axios
            .get(`/files/${itemId}/delete`)
            .then(() => {
                if (window.location.pathname === `/files/${itemId}`) {
                    window.location.pathname = '/files'
                }

                this.remove();

            });
    }

    needRefresh() {
        if (this.status == 'completed') {
            return false;
        }
        if (this.status == 'failed') {
            return false;
        }
        return true;
    }

    updateStatus(status) {
        if (status == 'queue' || status == 'in_progress') {
            return
        }

        this.loader.hide();
        this.deleteBtn.show();
    }


}
