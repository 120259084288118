import TechSupportApi from "../api/techSupport.js";
import {Button} from "../ui/components/Button";
import {getCorrectWord} from '../utils/prulization'

export class SendBtn extends Button {
    init() {
        super.init();

        this.includeComponents({
            'addressInput': 'document@#address',
            'bioInput': 'document@#bio',
            'attachmentInput': 'document@#attachment',
            'attachmentLabel': 'document@.attachment-label',
            'typeInput': 'document@#type',
            'invoiceInput': 'document@#invoice',
            'errorBagAddress': 'document@.invalid-feedback-address',
            'errorBagBio': 'document@.invalid-feedback-bio',
            'errorBagAttachment': 'document@.invalid-feedback-attachment',
            'bioTip': 'document@.bio-tip',
        });

        this.attachmentInput.on('change', this.onChangeAttachmentInput.bind(this))
        this.on('click', this.onClick.bind(this));
    }

    onChangeAttachmentInput(e) {
        this.attachmentLabel.element.innerText = (e.target.files.length > 0) ? e.target.files[0].name : getCorrectWord({
            ru: 'Файл не выбран',
            en: 'No file selected',
            es: 'No se ha seleccionado ningún archivo',
            fr: 'Aucun fichier sélectionné',
            kz: 'Файл таңдалған жоқ',
        });
    }

    onClick(e) {
        e.preventDefault();
        this.disable();

        if (!this.validate()) {
            return this;
        }

        TechSupportApi.submit({
            address: this.addressInput.element.value,
            bio: this.bioInput.element.value,
            attachment: this.attachmentInput.element.files[0],
            type: this.typeInput.element.value,
            invoice: this.invoiceInput.element.value
        }).then((response) => {
            if (response.status == 'sent') {
                window.location.reload();
            }
        }).catch((error) => {
            console.log(this.errorBagAddress)
            if (error.address) {
                this.addressInput.element.className += ' is-invalid';
                this.errorBagAddress.element.innerText = error.address;
            }

            if (error.bio) {
                this.bioInput.element.className += ' is-invalid';
                this.bioTip.element.className += ' d-none';
                this.errorBagBio.element.innerText = error.bio;
            }

            if (error.attachment) {
                this.attachmentInput.element.className += ' is-invalid';
                this.errorBagAttachment.element.innerText = error.attachment;
            }

            this.enable();
        });

        return this;
    }

    validate() {
        let valid = true;
        if (this.addressInput.element.value == '') {

            this.addressInput.element.classList.add('is-invalid');
            this.errorBagAddress.element.innerText = getCorrectWord({
                ru: 'Поле «Отправитель» обязательно для заполнения',
                en: 'The «Sender» field is required',
                fr: 'Le champ «Expéditeur» est obligatoire.',
                es: 'El campo «Remitente» es obligatorio.',
                kz: '«Жіберуші» өрісі міндетті түрде толтырылуы тиіс.'
            });
            valid = false;
        } else {
            let check = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu.test(this.addressInput.element.value)
            if (!check) {
                this.addressInput.element.classList.add('is-invalid');
                this.errorBagAddress.element.innerText = getCorrectWord({
                    ru: 'Поле «Отправитель» должно быть действительным электронным адресом',
                    en: 'The «Sender» field must be a valid email address',
                    fr: 'Le champ «Expéditeur» doit être une adresse e-mail valide',
                    es: 'El campo «Remitente» debe ser una dirección de correo electrónico válida',
                    kz: '«Жіберуші» өрісі заңды электронды пошта мекенжайы болуы керек'
                });
                valid = false;
            } else {
                this.addressInput.element.classList.remove('is-invalid');
                this.errorBagAddress.element.innerText = '';
            }
        }

        if (this.bioInput.element.value.length < 30) {
            this.bioInput.element.classList.add('is-invalid');
            this.bioTip.element.classList.add('d-none');
            this.errorBagBio.element.innerText = getCorrectWord({
                ru: 'Количество символов в поле «Сообщение» должно быть не менее 30',
                en: 'The number of characters in the «Message» field must be at least 30',
                fr: 'Le nombre de caractères dans le champ «Message» doit être d\'au moins 30',
                es: 'La cantidad de caracteres en el campo «Mensaje» debe ser de al menos 30',
                kz: '«Хабарлама» өрісіндегі символдар саны кемінде 30 болуы тиіс'
            });
            valid = false;
        } else {
            this.bioInput.element.classList.remove('is-invalid');
            this.bioTip.element.classList.remove('d-none');
            this.errorBagBio.element.innerText = '';
        }

        if (!valid) {
            this.enable();
        }

        return valid;
    }
}
