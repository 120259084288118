import TbxComponent from "tbxjs/component";

let selectedBlock;

export class BlockItem extends TbxComponent {
    init() {
        this.on('click', () => {
            window.location.href = this.href + '?after=' + this.parent.selectedBlock + '&type=' + this.type;
        })
    }

}