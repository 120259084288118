/**
 * Plural forms for russian words
 * @param  {Integer} count quantity for word
 * @param  {Array} words Array of words. Example: { ru: ['минут', 'минута', 'минуты', 'минут'], en: ['minute', 'minutes'] }
 * @return {String} Count + plural form for word
 */
export function pluralizeWord(count, words = null) {
    const sessionLocale = window.app.currentLocale;

    if (!sessionLocale || !count || !words) {
        return ''
    }

    if (sessionLocale === 'ru') {
        return pluralizeRussianWord(count, words[sessionLocale])
    }
    return pluralizeEnglishWord(count, words[sessionLocale])
}

function pluralizeRussianWord(count, words) {
    const lastDigit = count % 10
    const lastTwoDigits = count % 100
    switch(true) {
        case (lastTwoDigits >= 11 && lastTwoDigits <= 19):
            return `${count} ${words[0]}`
        case (lastDigit === 1):
            return `${count} ${words[words.length - 3]}`
        case (lastDigit >= 2 && lastDigit <= 4):
            return `${count} ${words[words.length - 2]}`
        default:
            return `${count} ${words[words.length - 1]}`
    }
}

function pluralizeEnglishWord(count, word) {
    return `${count} ${word}${count !== 1 ? 's' : ''}`;
}

/**
 * Plural forms for russian words
 * @param  {Object} wordCollection Example: { ru: '₽', en: '$' }
 * @return {String} Word from current Locale
 */
export function getCorrectWord(wordCollection = null) {
    const sessionLocale = window.app.currentLocale;

    if (!wordCollection || !sessionLocale) {
        return ''
    }

    return wordCollection[sessionLocale]
}
