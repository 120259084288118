import { RANGE_MIN_VALUE, RANGE_MAX_VALUE } from './constants'
import UIComponent from '../../ui/component'

export class PaymentRangeFieldForm extends UIComponent {
    init() {
        this.input.on('input', (e) => {
            // console.log(e, this.input.element.value);
            this.onChange(this.coordinateToMinutes(this.input.element.value));
            this.updateProgressLine()
            // this.calculateRangeMinutes(this.priceRanges)
        });

        this.priceRanges = window.app.prices;

        this.priceRanges.forEach((element, index) => {
            this.rate[index].price = element.price;
        });
    }

    onChange(minutes) {

    }

    setValue(minutes) {
        if (minutes < RANGE_MIN_VALUE) {
            minutes = RANGE_MIN_VALUE;
        }

        if (minutes > RANGE_MAX_VALUE) {
            minutes = RANGE_MAX_VALUE;
        }
        this.input.element.value = this.minutesToCoordinates(minutes);
        this.updateProgressLine();
        // this.input.element.dispatchEvent(new Event('change'));
    }

    getRangeParams(val) {
        let rangeDelta = 100 / this.priceRanges.length;
        let currentRangeIndex = Math.floor(val / rangeDelta);
        if (val == 100) {
            currentRangeIndex = this.priceRanges.length - 1;
        }
        let currentRange = this.priceRanges[currentRangeIndex];

        return {
            rangeDelta,
            currentRangeIndex,
            currentRange,
        };
    }

    getRangeParamsByMinutes(val) {
        let rangeDelta = 100 / this.priceRanges.length;
        let currentRangeIndex = 0;
        let currentRange;
        this.priceRanges.forEach(element => {
            if (val >= element.range[0]) {
                currentRange = element;
                currentRange.index = currentRangeIndex;
            }
            currentRangeIndex++;
        });

        return {
            rangeDelta,
            currentRangeIndex,
            currentRange,
        };
    }

    coordinateToMinutes(val) {
        let { rangeDelta, currentRange, currentRangeIndex} = this.getRangeParams(val);
        let offsetInRange = (val - currentRangeIndex*rangeDelta) / rangeDelta;
        let minutes = currentRange.range[0] + (currentRange.range[1] - currentRange.range[0]) * offsetInRange;
        // console.log(currentRange, offsetInRange, minutes, currentRange.range[0], currentRange.range[1]);
        return Math.ceil( minutes / currentRange.step)*currentRange.step;
    }

    minutesToCoordinates(val) {
        let { rangeDelta, currentRange } = this.getRangeParamsByMinutes(val);
        return Math.round( ((val - currentRange.range[0]) / ( currentRange.range[1] - currentRange.range[0])) * rangeDelta + rangeDelta * currentRange.index );

    }

    updateProgressLine() {
    //     const range = RANGE_MAX_VALUE - RANGE_MIN_VALUE
    //     const preparedVal = Number(this.input.element.value) - RANGE_MIN_VALUE

        const completePercent = Number(this.input.element.value)

        this.progressline.css('width', completePercent + '%')
        this.thumb.css('transform', 'translateX(' + completePercent + '%)')

        this.updateCurrentPoint();
        this.updateCurrentStep();
    }

    updateCurrentStep() {
        const val = Number(this.input.element.value)
        const current = Math.floor(val / ( 100 / this.priceRanges.length ) );

        this.steps.forEach((element, index) => {
            if (current >= index) {
                element.addClass('--active');
            } else {
                element.removeClass('--active');
            }
        });
    }

    updateCurrentPoint() {
        const val = Number(this.input.element.value)
        const current = Math.floor(val / ( 100 / this.priceRanges.length ) );

        this.rate.forEach((element, index) => {
            if (current >= index) {
                element.addClass('--active');
            } else {
                element.removeClass('--active');
            }
        });
    }
}
