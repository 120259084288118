import { Modal } from "../ui/components/Modal";
import api from "../api";

export class PaymentTinkoff extends Modal {
    initialized = false;
    type = 'tinkoff';
    script = null;

    init() {
        super.init();

        if (this.initialized) {
            return;
        }

        this.script = document.createElement("script");
        this.script.src = 'https://securepay.tinkoff.ru/html/payForm/js/tinkoff_v2.js';

        document.head.appendChild(this.script);

        this.TPF = document.getElementById('payform-tinkoff');

        this.initialized = true;
        this.payBtn.on('click', this.handleSubmit.bind(this));
    }

    async show(paymentData) {
        // console.log(paymentData);
        if (!paymentData) {
            return
        }
        this.paymentData = paymentData;

        this.payBtn.amount = paymentData.Amount;
        this.payBtn.enable();

        super.show();
    }

    async handleSubmit(e) {
        e.preventDefault();
        this.errorBag.hide();
        if (this.emailInput.element.value == '') {
            this.errorBag.show('Пожалуйста, заполните email');
            return;
        }

        if (this.emailInput.element.value != '') {
            let check = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu.test(this.emailInput.element.value)
            if (!check) {
                this.errorBag.show('Пожалуйста, введите корректный E-mail адрес');
                return;
            }
        }

        // if (this.phoneInput.element.value != '') {
        //     let check = /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10,14}(\s*)?$/.test(this.phoneInput.element.value)
        //     if (!check) {
        //         this.errorBag.show('Номер телефона не соответствует маске. Пример: +79998887766');
        //         return;
        //     }
        // }

        this.payBtn.disable();
        const { receipt } = this.TPF;

        if (receipt) {
            this.TPF.receipt.value = JSON.stringify(this.paymentData.Receipt);

        }
        this.TPF.amount.value = this.paymentData.Amount;
        this.TPF.description.value = this.paymentData.Description;
        this.TPF.order.value = this.paymentData.invoice;

        // console.log(this.TPF, this.paymentData);
        try {
            pay(this.TPF);
        } catch (e) {
            console.log(e);
            this.errorBag.show(e.message);
        }

        return;


        let result = false;
        try {
            result = await this.stripe.confirmPayment({
                elements: this.elements,
                confirmParams: {
                    return_url: this.paymentData.links.callback,
                },
            });

        } catch (e) {
            this.onError();
        }

        if (!result) {
            this.onError();
        }

        if (result.error) {
            // console.log(result);
            if (result.error.type === "card_error" || result.error.type === "validation_error") {
                //this.onError(result.error.message);
                this.errorBag.show(result.error.message);
                api.get(this.paymentData.links.callback + "?payment_intent=" + result.error?.payment_intent?.id)
            }
        }

        this.payBtn.enable();
    }

    onPaymentComplete(paymentResult, options) {

    }

    onPaymentFail(reason) {

    }

    /** Общие события виджета */
    onSuccess(response, paymentData) {

    }

    onCancel() {

    }

    onError(errors) {
    }

}
