import { Modal } from "../ui/components/Modal";
import api from "../api";

export class PaymentCloudpayments extends Modal {
    initialized = false;
    type = 'cloudpayments';

    init() {
        super.init();

        if (this.initialized) {
            return;
        }

        var script = document.createElement("script");
        script.src = 'https://widget.cloudpayments.ru/bundles/cloudpayments.js';

        document.head.appendChild(script);

        this.initialized = true;
    }

    show(paymentData) {
        if (!paymentData) {
            return
        }
        this.paymentData = paymentData;

        let receipt = {
            "Items": [//товарные позиции
                {
                    "label": "Минута", //наименование товара
                    "price": paymentData.amount, //цена
                    "quantity": 1.00, //количество
                    "amount": paymentData.amount, //сумма
                    "vat": 0, //ставка НДС
                    "method": 0, // тег-1214 признак способа расчета - признак способа расчета
                    "object": 0, // тег-1212 признак предмета расчета - признак предмета товара, работы, услуги, платежа, выплаты, иного предмета расчета
                    "measurementUnit": "шт" //единица измерения
                },
            ],
            "taxationSystem": 1, //система налогообложения; необязательный, если у вас одна система налогообложения
            "isBso": false, //чек является бланком строгой отчётности
            "amounts":
            {
                "electronic": paymentData.amount, // Сумма оплаты электронными деньгами
                "advancePayment": 0.00, // Сумма из предоплаты (зачетом аванса) (2 знака после запятой)
                "credit": 0.00, // Сумма постоплатой(в кредит) (2 знака после запятой)
                "provision": 0.00 // Сумма оплаты встречным предоставлением (сертификаты, др. мат.ценности) (2 знака после запятой)
            }
        };

        let data = {
            CloudPayments: {
                CustomerReceipt: receipt, //чек для первого платежа
            }
        };

        this.widget = new cp.CloudPayments({ language: 'ru-RU' })
        this.widget.pay('charge', // 'charge' - одностадийная оплата(без подтверждения с нашей стороны), 'auth' - двустадийная оплата (требуется подтверждение с нашей стороны)
            { //options
                publicId: paymentData.publicId, //id из личного кабинета
                description: paymentData.description, //описание
                amount: paymentData.amount, //сумма
                currency: paymentData.currency, //валюта
                invoiceId: paymentData.invoice, //номер заказа  (необязательно)
                data: data,
                skin: "mini", //дизайн виджета (необязательно)
                autoClose: 5,
                configuration: {
                    common: {
                        successRedirectUrl: paymentData.links.callback, // адреса для перенаправления
                        failRedirectUrl: paymentData.links.callback  // при оплате по T-Pay
                    }
                },
            },
            {
                onComplete: this.onPaymentComplete.bind(this), //Вызывается как только виджет получает от api.cloudpayments ответ с результатом транзакции.
                onFail: this.onPaymentFail.bind(this),
            }
        );

    }

    onPaymentComplete(paymentResult, options) {
        console.log('onPaymentComplete', paymentResult, options, this.paymentData)
        //TODO когда будет фронт, нужно будет переделать, чтобы в колбэк в любом случае был переход, так же при повторном платеже форма не закрывалась и тд.

        api.post(this.paymentData.links.complete, { paymentResult, options }).then((response) => {

        });

        if (paymentResult.success) {
            api.post(this.paymentData.links.callback, { paymentResult, options }).then((response) => {
                this.onSuccess(response, this.paymentData);
                this.paymentData = null;
            }).catch(errors => {
                this.onError(errors);
                this.paymentData = null;
            });

            return;
        }

        this.onError();
    }

    onPaymentFail(reason) {
        console.log('onPaymentFail', reason)
        // if (reason == 'User has cancelled') {
            this.onCancel();
        // }
        this.paymentData = null;
    }

    /** Общие события виджета */
    onSuccess(response, paymentData) {

    }

    onCancel() {

    }

    onError(errors) {
    }

}
