import { Modal } from "../ui/components/Modal";
import api from "../api";

export class PaymentStripe extends Modal {
    initialized = false;
    type = 'stripe';
    script = null;

    init() {
        super.init();

        if (this.initialized) {
            return;
        }

        this.script = document.createElement("script");
        this.script.src = 'https://js.stripe.com/v3/';

        document.head.appendChild(this.script);

        this.initialized = true;
        this.payBtn.on('click', this.handleSubmit.bind(this));
    }

    async show(paymentData) {
        // console.log(paymentData);
        if (!paymentData) {
            return
        }
        this.paymentData = paymentData;

        this.stripe = Stripe(window.app.payments.stripe.client_id);

        const appearance = {
            theme: 'flat',
            variables: { colorPrimaryText: '#2932FF' }
        };

        this.elements = this.stripe.elements({ clientSecret: paymentData.secret_key_payment, appearance });

        this.payBtn.amount = paymentData.amount;

        const paymentElementOptions = {
            // layout: "tabs",
        };

        document.querySelector('#stripePaymentModalContent').innerHTML = '';

        const paymentElement = this.elements.create("payment", paymentElementOptions);

        this.payBtn.disable();
        paymentElement.on('ready', () => {
            this.payBtn.enable();
        });

        paymentElement.mount("#stripePaymentModalContent");

        super.show();
    }

    async handleSubmit() {
        this.payBtn.disable();
        // console.log('handleSubmit');
        let result = false;
        try {
            result = await this.stripe.confirmPayment({
                elements: this.elements,
                confirmParams: {
                    return_url: this.paymentData.links.callback,
                },
            });

        } catch (e) {
            this.onError();
        }

        if (!result) {
            this.onError();
        }

        if (result.error) {
            // console.log(result);
            if (result.error.type === "card_error" || result.error.type === "validation_error") {
                //this.onError(result.error.message);
                this.errorBag.show(result.error.message);
                api.get(this.paymentData.links.callback + "?payment_intent=" + result.error?.payment_intent?.id)
            }
        }

        this.payBtn.enable();
    }

    onPaymentComplete(paymentResult, options) {

    }

    onPaymentFail(reason) {

    }

    /** Общие события виджета */
    onSuccess(response, paymentData) {

    }

    onCancel() {

    }

    onError(errors) {
    }

}
