import UIComponent from '../ui/component';

export class FilesList extends UIComponent {
    init() {
    }

    getItemById(id) {
        let result;
        this.items.forEach(item => {
            if (item.itemid == id) {
                result = item;
            }
        });
        return result;
    }

    getFilesIds() {
        let ids = [];
        this.items?.forEach(item => {
            ids.push(item.itemid);
        });
        return ids;
    }

    getFilesIdsNeedsRefresh() {
        let ids = [];
        this.items?.forEach(item => {
            if (item.needRefresh()) {
                ids.push(item.itemid);
            }
        });
        return ids;
    }

    updateItemById(id, data) {
        let item = this.getItemById(id);
        if (!item) { return; }

        item.updateStatus(data.status);
    }

    filterItems(search) {

        this.items.forEach(item => {
            if (search.toLowerCase == '' || !item.filename?.includes(search.toLowerCase())) {
                item.hide();
            } else {
                item.show();
            }
        });

    }

}
