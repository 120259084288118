import Qs from 'qs';

const axios = window.axios;

class Api {
    async get(url, payload) {
        // payload = Qs.stringify(payload, { arrayFormat: 'brackets' });

        return new Promise((resolve, reject) => {
            axios.get(url, payload).then((response) => {
                this.processResponse(response, resolve, reject)
            }).catch((error) => {
                this.processResponse(error, resolve, reject)
            });
        });
    }

    async post(url, payload) {
        payload = Qs.stringify(payload, { arrayFormat: 'brackets' });

        return new Promise((resolve, reject) => {
            axios.post(url, payload).then((response) => {
                this.processResponse(response, resolve, reject)
            }).catch((error) => {
                this.processResponse(error, resolve, reject)
            });
        });
    }

    processResponse(response, resolve, reject) {
        console.log('processResponse', response);
        // Для ответов-эксепшенов
        if (response.response) {
            response = response.response;
        }

        if (response.data?.data?.errors) {
            // В ответе есть ошибки, дальше не идем
            //this.errorBag.show(response.data?.data?.errors);
            reject(response.data?.data?.errors);
        }

        if (response.data?.redirect) {
            // Редиректим на другую страницу
            window.location.href = response.data.redirect;
            return;
        }
        if (response.data?.data) {
            resolve(response.data.data);
        }
        resolve(response.data);
    }
}

export { Api as AbstractApi }

export default new Api;
