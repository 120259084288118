import '../audio/recorder.js';
import UIComponent from "../ui/component.js";
import api from '../api/index.js';

export class UploadLinkForm extends UIComponent {

    init() {
        this.reset();

        this.submitBtn.on('click', (e) => {
            e.preventDefault();
            this.startUploadByLink()
        });

        this.link.on('keypress', (keyPressEvent) => {
            if (keyPressEvent.key !== 'Enter') {
                return
            }
            this.startUploadByLink()
        })

    }

    // Возвращает форму в исходное состояние
    reset() {
        this.submitBtn.enable();
    }

    onSuccess(file) {

    }

    onError() {

    }

    startUploadByLink () {
        let url = this.attr('data-url');
        if (!url) {
            return
        }

        this.submitBtn.disable();

        api.post(url, {
            link: this.link.element.value
        })
            .then((data) => { this.onSuccess(data) })
            .catch((error) => this.onError(error))
            .finally(() => {
                this.reset();
            })
    }
}
