import { AbstractApi } from ".";

class FilesApi extends AbstractApi {
    transcodeUrl = '/api/files/transcribe';

    async calculate(files) {
        let payload = files;
        if (typeof files == 'string') {
            payload = { files: [files] };
        }
        console.log(files, payload);
        return await this.post(this.transcodeUrl, payload);
    }

    async transcribe(files) {
        let payload = files;
        // console.log(typeof files, files);
        if (typeof files == 'string') {
            payload = { files: [files] };
        }
        payload.addToQueue = 1;
        // console.log(files, payload);
        return await this.post(this.transcodeUrl, payload);
    }

    async transcribeWithLanguage(filePath, language) {

        await this.transcribe({ files: [filePath], options: { 'language': language }, toLanguage: 1 });

        // const transcribeQueryResponse = { url: '/api/files/transcribe', payload: { files: [filePath], options: { 'language': language } } }
        // if (transcribeData.paymentRequired) {

        //     transcribeQueryResponse.url = `/files/${location.pathname.replace('/files/', '')}/surcharge`
        //     transcribeQueryResponse.payload = {}
        // }

        // const { data } = await axios.post(
        //     transcribeQueryResponse.url,
        //     Object.assign(transcribeQueryResponse.payload, { addToQueue: 1 }),
        // );

        // if (transcribeData.paymentRequired) {
        //     return { needPay: true, paymentData: data?.data?.payment }
        // }

        // window.location = data?.redirect
    }
}

export default new FilesApi;
