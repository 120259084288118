import { Modal } from "../ui/components/Modal";
import PromocodeApi from "../api/promocode";

export class PromocodeModal extends Modal {

    init() {
        super.init();

        this.includeComponents({
            'authModal': "document@#authModal",
        });

        this.input.on('keyup', this.onInputChange.bind(this));
        this.input.on('change', this.onInputChange.bind(this));
        this.submitBtn.on('click', this.onSubmit.bind(this));
        this.cancelBtn.on('click', () => {
            this.onSelectCode(null);
            // this.hide();
        });
        this.applyBtn.on('click', this.onApply.bind(this));
        this.authBtn.on('click', this.onApply.bind(this));
    }

    show() {
        this.input.element.value = '';
        this.input.enable();
        this.onInputChange();


        this.info.hide();
        this.submitBtn.disable();
        this.applyBtn.hide();
        this.cancelBtn.hide();
        this.authBtn.hide();

        super.show();
    }

    onInputChange(e) {
        if (e?.keyCode === 13) {
            // Enter
            this.onSubmit();
            return;
        }

        if (this.input.element.value.length > 0) {
            this.submitBtn.enable();
        } else {
            this.submitBtn.disable();
        }
    }

    onSubmit() {
        this.onSelectCode(this.input.element.value);
        this.submitBtn.disable();
        PromocodeApi.check(this.selectedCode).then((response) => {
            // this.submitBtn.enable();

            this.info.show();
            this.info.text = response.text;
            this.info.description = response.description;

            if (response.authRequired) {
                this.authBtn.show();
                this.authBtn.enable();
                this.cancelBtn.show();
            } else {
                this.applyBtn.show();
                this.cancelBtn.show();
            }
            this.errorBag.hide();


        }).catch((error) => {
            // console.log(error);

            this.errorBag.show(error);
            this.onSelectCode(null);
        });
    }

    onSelectCode(code) {
        this.selectedCode = code;
        if (this.selectedCode == null) {
            this.input.enable();
            this.applyBtn.hide();
            this.cancelBtn.hide();
            this.authBtn.hide();
            this.info.hide();
            this.onInputChange();
        } else {
            this.input.disable();
        }
    }

    onApply() {
        this.authBtn.disable();
        this.applyBtn.disable();

        PromocodeApi.apply(this.selectedCode).then((response) => {

            if (response.authRequired) {
                this.authModal.show();
            } else {
                window.location.reload();
            }


        }).catch((error) => {
            this.applyBtn.enable();
            this.authBtn.enable();

            this.errorBag.show(error);
        });
    }

}
