import goals from "../utils/goals";
import Api from "../api";
import { Button } from "../ui/components/Button";

export class SubscriptionBtn extends Button {


    init() {
        super.init();

        this.includeComponents({
            'authModal': 'document@#authModal',
            'paymentModal': 'document@#paymentModal',
            'paymentSelectModal': 'document@#paymentSelectModal',
        });

        this.on('click', this.onClick.bind(this));

    }

    onClick(e) {
        e.preventDefault();
        if (!this.authorized) {
            this.authModal.show().showPrecaution();
            return;
        }
        console.log("Authorized", this.url, this);
        this.paymentSelectModal.show(this, this.url, { sub: this.sub });
    }

    onPaymentSuccess(response, paymentData) {
        if (paymentData.links.account != null) {
            setInterval(() => {
                window.location.href = paymentData.links.account;
            }, 1000)
        } else {
            setInterval(() => {
                window.location.reload();
            }, 5000)
        }

        this.payBtn.enable();
    }

    onPaymentCancel(response) {
        if (response.links.account != null) {
            window.location.href = response.links.account;
        }
        this.payBtn.enable();
        this.show();
    }

    onPaymentError(errors) {
        // console.log(errors);
    }
}
