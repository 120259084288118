import UIComponent from "../ui/component";
import { Modal } from "../ui/components/Modal";

export class AuthModal extends Modal {
    show() {
        super.show();
        this.precaution.hide();
        return this;
    }

    showPrecaution() {
        this.precaution.show();
    }
}
