import { AbstractApi } from ".";

class PromocodeApi extends AbstractApi {
    checkUrl = '/api/promocode/check';
    applyUrl = '/api/promocode/apply';

    async check(promocode) {
        return this.post(this.checkUrl, { promo: promocode })
    }

    async apply(promocode) {
        return this.post(this.applyUrl, { promo: promocode })
    }

}

export default new PromocodeApi;