import goals from "../utils/goals";
import { UploadCheckout } from "./checkout";
import FilesApi from "../api/files";
export class UploadCheckoutPromo extends UploadCheckout {

    init() {
        super.init();
        this.transcribeBtn.on('click', this.onTranscribeBtnClick.bind(this));
    }

    update(files) {
    }

    onTranscribeBtnClick(e) {
        e.preventDefault();

        if (!this.acceptTerms.isChecked()) {
            this.acceptTerms.shake(30, 5, 5);
            return;
        }

        goals.reach('transcodePromo');

        this.transcribeBtn.disable();

        FilesApi.transcribe(
            this.parent.getFiles(),
        ).then((response) => {
            this.transcodeBtn.enable();
        }).catch((errors) => {
            this.transcodeBtn.enable();
            this.errorBag.show(errors)
        })

    }
}
